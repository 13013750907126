<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <span class="brand-logo">
            <!-- <b-img :src="appLogoImage" width="180px" alt="logo" /> -->
            <b-img
              v-if="skin === 'light'"
              src="@/assets/images/alllogos/APP-Dark.png"
              width="180px"
              alt="logo"
            />
            <b-img
              v-else
              src="@/assets/images/alllogos/APP-Light.png"
              alt="logo"
              width="180px"
            />
          </span>
          <!-- <h2 class="brand-text text-primary ml-1">
          Secusy
        </h2> -->
        </b-link>

        <b-card-title class="mb-1"> Reset Password 🔒 </b-card-title>
        <b-card-text class="mb-2"> Please set your password here </b-card-text>

        <!-- form -->
        <validation-observer ref="loginValidation">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent
          >
            <!-- password -->
            <b-form-group label="New Password" label-for="reset-password-new">
              <template slot="label">
                New Password <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    @input="password_check"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div class="row mt-1">
                <div class="col-6">
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': password.length > 9 }"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="frmIcon"
                      :class="password.length ? 'fa-check' : 'fa-times'"
                    />
                    At least 10 characters
                  </p>
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': has_uppercase }"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="frmIcon"
                      :class="has_uppercase ? 'fa-check' : 'fa-times'"
                    />
                    Has a capital letter
                  </p>
                </div>
                <div class="col-6">
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': has_lowercase }"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="frmIcon"
                      :class="has_lowercase ? 'fa-check' : 'fa-times'"
                    />
                    Has a lowercase letter
                  </p>
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': has_number }"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="frmIcon"
                      :class="has_number ? 'fa-check' : 'fa-times'"
                    />
                    Has a number
                  </p>
                </div>
                <div class="col-12">
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': has_special }"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="frmIcon"
                      :class="has_special ? 'fa-check' : 'fa-times'"
                    />
                    Has a special character
                  </p>
                </div>
              </div>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirm Password"
            >
              <template slot="label">
                Confirm Password <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="confirm"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- (password !== '' && password.length < 10) -->
            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
              :disabled="
                (password !== '' && !has_number) ||
                (password !== '' && !has_lowercase) ||
                (password !== '' && !has_uppercase) ||
                (password !== '' && !has_special) ||
                (password !== '' && password.length < 10)
              "
              @click="validationForm"
            >
              <span v-if="loading">Please wait</span>
              <span v-else>Set New Password</span>
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
} from "bootstrap-vue";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import store from "@/store/index";

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  data() {
    return {
      userEmail: "",
      // cPassword: "",
      confirm: "",
      password: "",
      // validation
      required,
      setToken: "",

      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password",

      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      loading: "",
      showOverlay: false,
    };
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  mounted() {
    this.setToken = this.$route.query.token;
  },
  methods: {
    password_check: function () {
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password);
    },
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    validationForm() {
      this.$refs.loginValidation
        .validate()
        .then((success) => {
          this.loading = true;
          console.log(success);
          if (success) {
            const data = {
              password: this.password,
              token: this.$route.query.token,
            };
            this.$store
              .dispatch("app/resetPassword", data, { root: true })
              .then((res) => {
                // this.handleResponse(res);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "AwardIcon",
                    variant: "success",
                  },
                });
                this.$router.push("/login");
              })
              .catch((err) => {
                if (err.response.data.code == 422) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: err.response.data.errors.password[0],
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: err.response.data.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                }
                // this.handleError(err);
              })
              .finally(() => {
                // this.showOverlay = false;
                this.loading = false;
              });
          }
        })
        .catch((err) => {
          // this.showOverlay = false;
          this.loading = false;
        });
    },
  },
  setup() {
    const { appName, appLogoImage, appDarkLogoImage } = $themeConfig.app;
    const { skin } = useAppConfig();
    return {
      // App Name
      appName,
      appLogoImage,
      appDarkLogoImage,
      skin,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.frmField {
  background-color: white;
  color: #495057;
  line-height: 1.25;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  border: 0;
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  width: 90%;
}
.frmLabel {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.frmValidation {
  font-size: 13px;
}
.frmValidation--passed {
  color: #717b85;
}
.frmIcon {
  color: #eb0029;
}
.frmValidation--passed .frmIcon {
  color: #0fa140;
}
</style>
